.vertical-line {
  align-items: center;
  margin: 0 10rem;
}

.line {
  height: 1px;
  background-color: rgba(31, 31, 31, 0.1);
}

.or-text {
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  padding: 0;
}
