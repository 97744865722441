@import "~bootstrap/scss/bootstrap";

.btn-outline-primary,
.btn-primary {
  border-color: #ff7f50;
  font-weight: 600;

}

h2,
h6 {
  font-weight: 600 !important;
}

.btn-outline-primary {
  color: #ff7f50;
  &:hover {
    background-color: darken(#ff7f50, 10%);
    border-color: darken(#ff7f50, 10%);
  }
}

.btn-primary {
  background-color: #ff7f50;

  &:hover {
    background-color: darken(#ff7f50, 10%);
    border-color: darken(#ff7f50, 10%);
  }

  &:active,
  &.active {
    background-color: darken(#ff7f50, 20%);
    border-color: darken(#ff7f50, 20%);
  }
}

.btn:disabled {
  color: #c4c4c4;
  border-color: #c4c4c4;
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.text-primary {
  color: #ff7f50 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.extra-rounded {
  border-radius: 5%;
}

a {
  color: #ff7f50;
}

.form-label {
  font-size: 90%;
  font-weight: 600;
}

h1 {
  font-size: 2.1rem;
  font-weight: 600;
}

.carousel-inner {
  height: 100%;
}

.upload-file-btn-container {
  > div > button {
    border: none;
    color: white;
    background-color: #ff7f50;
    padding: 0.35rem 3.5rem;
    border-radius: 0.25rem;
  }
}

.form-check-input:focus {
  border-color: #ff7f50;
  box-shadow: 0 0 0 0.25rem rgba(255, 127, 80, 0.25);
}

.form-check-input:checked {
  background-color: #ff7f50;
  border-color: #ff7f50;
}


.page-link.active, .active > .page-link{
  background-color: #ff7f50;
  color: white;
  border-color: #ff7f50;
}

.page-link{
  color: #ff7f50;
}

.page-link:hover{
  color: #ff7f50;
}

.page-link.active, .active > .page-link:hover{
  color: white;
}

.dropdown-item.active, .dropdown-item:active{
  background-color:#ff7f50;
}
.sb-avatar__image{
  object-fit: cover;
}
.show-more-less-clickable{
  font-weight: bold;
}

.mobile-horizontal-padding{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.ps-sidebar-container{
  background-color: white !important;
}

#blog-content{
  img{
    width: 100%;
  }
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:  rgba(31, 31, 31, 0.2);
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:  rgba(31, 31, 31, 0.2);
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color:  rgba(31, 31, 31, 0.2);
}