.categories-tab .nav-link.active,
.categories-tab .nav-item.show .nav-link {
  color: #ff7a59;
  background-color: transparent !important;
  border: 0;
}

.categories-tab .nav-link {
  color: black;
  font-weight: 600;
}


.categories-tab .nav-item{
  width: 50%;
}

.categories-tab .nav-item button{
  width: 100%;
}
.categories-tab{
  background-color: #F5F5F580;
}