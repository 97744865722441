.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CSS file */
.container {
  max-width: 1000px; /* Default max-width for all breakpoints */
}

@media (min-width: 576px) {
  .container {
    max-width: 1200px; /* Max-width for small devices and up */
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 1400px; /* Max-width for medium devices and up */
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1600px; /* Max-width for large devices and up */
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1800px; /* Max-width for extra large devices and up */
  }
}

.bg-grey {
  background-color: #f5f5f5;
}

.content-card-image {
  height: 67.5%;
  background-color: #c4c4c4;
}

.content-card-image > img {
  height: 100%;
}

.bordered-heading {
  padding-bottom: 0.75rem;
  display: inline-block; /* Ensures the border only covers the content width */
  border-bottom: 4px solid #ff7a49; /* Adjust border properties as desired */
  font-weight: bold;
}

.bg-dashboard {
  background-color: #ff7f50;
}

.bg-orange {
  background-color: #ff7f50;
}

.react-pdf__Page__canvas {
  margin: auto !important;
}

@media (max-width: 600px) {
  .helper-text {
    font-size: 80%;
  }
}

[class*="indicatorSeparator"] {
  display: none;
}


.custom-multilevel-dropdown {
  position: relative;
  font-size: 1rem !important;
}

.custom-multilevel-dropdown::after {
  content: "";
  position: absolute;
  top: 40%;
  right: -10px; /* Adjust the right spacing as needed */
  transform: translateY(-50%);
  border: solid;
  border-width: 0 0.15rem 0.15rem 0;
  display: inline-block;
  padding: 0.15em;
  width: 0.4em;
  height: 0.4em;
  border-color: #000; /* Change the color as needed */
  transform: rotate(45deg);
  transition: transform 0.3s ease; /* Add transition for a smooth effect */
}

.breadcrumb-item {
  font-weight: bold;
  + .breadcrumb-item::before {
    content: ">" !important;
    align-self: baseline;
    color: #ff7f50;
  }
}
.breadcrumb{
  margin-bottom: 0;
}