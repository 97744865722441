.accordion-button:focus {
  border-color: #ff7f50;
  box-shadow: 0 0 0 0.25rem #ff7f50;
}

.accordion-button:not(.collapsed) {
  color: #ff7f50;
  background-color: transparent;
  font-weight: bold;
  border: none;
}
