.applications-tabs .nav-link.active,
.applications-tabs .nav-item.show .nav-link {
  color: #ff7a59;
  background-color: #fff1ec;
  border: 0;
}

.applications-tabs .nav-link {
  color: rgba(31, 31, 31, 0.5);
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .applications-tabs .nav-link {
    font-size: 75%;
    padding-left: 10px;
    padding-right: 10px;

  }
}

@media screen and (min-width: 767px) {
  /* Your mobile-specific CSS rules here */
}
