.search-input {
  position: relative;
  width: 80%;
}

.search-input .search-icon {
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
}

