.product-tabs .nav-link.active,
.product-tabs .product-tab.show .nav-link {
  background-color: #ff7f50;
  color: white;
  font-weight: bold;
}

.product-tabs .nav-link {
  color: black;
}
